import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  GET_INVOICES,
  GET_REFUND_REQUESTS_BY_TRUSTEE_ID,
  GET_TRUSTEES_ALL_SCHOOL,
  GET_TRUSTEES_REQUESTS,
  GET_VENDORS,
  UPDATE_VENDOR_STATUS,
} from "../Queries";
import Table from "../../../../../components/Table/Table";
import Modal from "../../../../../components/Modal/Modal";
import RateChargeModal from "../../../../../components/Modal/RateChargeModal";
import SchoolRate from "../Tabs/CurrentChargeTab/SchoolRate";
import RequestTab from "../Tabs/RequestTab/RequestTab";
import RefundRequestTab from "../Tabs/RefundRequestTab/RefundRequestTab";

import SetSchoolGatwayRate from "./SetSchoolGatwayRate";

import { FaRegEye } from "react-icons/fa";
import { toast } from "react-toastify";
import { HiOutlineDotsVertical } from "react-icons/hi";
import UpdateInvoice from "./UpdateInvoice";
import ConfirmationBox from "../../../../../components/ConfermationBox";

const VendorStatusUpdate = (data: any) => {
  const { school_id, trustee_id, vendor_id } = data.data;

  const [openModal, setOpenModal] = useState(false);
  const [updateVendorStatus] = useMutation(UPDATE_VENDOR_STATUS);
  return (
    <div>
      <Modal open={openModal} setOpen={setOpenModal} className=" max-w-xl">
        <ConfirmationBox
          setOpen={setOpenModal}
          funtion={async () => {
            try {
              const res = await updateVendorStatus({
                variables: {
                  school_id: school_id,
                  trustee_id: trustee_id,
                  vendor_id: vendor_id,
                },
              });

              if (res.data.updateVendorStatus) {
                toast.success(res.data.updateVendorStatus);
                data.fetch();
              }
            } catch (error: any) {
              toast.error(error.message);
              data.fetch();
            }
          }}
          confirmationText="Update Vendor Status"
          ButtonText="Confirm"
        />
      </Modal>
      {data.data.status === "INITIATED" ? (
        <HiOutlineDotsVertical
          onClick={() => {
            setOpenModal(true);
          }}
          className=" cursor-pointer mx-auto"
        />
      ) : (
        "-"
      )}
    </div>
  );
};

function SingleTrusteeSchools() {
  const searchId = useLocation();
  const id = searchId.pathname.slice(12);
  const [activeTab, setActiveTab] = useState(1);
  const [openRateChargeModal, setOpenRateChargeModal] = useState(false);
  const [openBulkMrdModal, setOpenBulkMrdModal] = useState(false);
  const [schoolID, setSchoolID] = useState(null);
  const [schoolData, setSchoolData] = useState<any>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [singleRequest, setSingleRequest] = useState<any>(null);

  const [openSetGatwayModal, setOpenSetGatwayModal] = useState(false);
  const [openInvoicePreview, setOpenInvoicePreview] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const { data: invoices } = useQuery(GET_INVOICES, {
    variables: {
      limit: 200,
      page: 1,
      trustee_id: id,
    },
  });

  const { data } = useQuery(GET_TRUSTEES_ALL_SCHOOL, {
    variables: {
      trusteeId: id,
    },
  });
  const { data: requestList } = useQuery(GET_TRUSTEES_REQUESTS, {
    variables: {
      trusteeId: id,
    },
  });

  const { data: refundRequests, refetch } = useQuery(
    GET_REFUND_REQUESTS_BY_TRUSTEE_ID,
    {
      variables: {
        trustee_id: id,
        page: 1,
        limit: 100,
      },
    }
  );

  const { data: getVendors, refetch: vendorRefetch } = useQuery(GET_VENDORS, {
    variables: {
      trustee_id: id,
      page: 1,
      limit: 100,
    },
  });

  const filterRequests = requestList?.getTrusteeRequest.map(
    (request: any, index: number) => {
      if (request.school_id.length > 1) {
        const schools = request.school_id.map((s: any) => {
          return data?.getAllTrusteeSchool.filter((school: any) => {
            return school.school_id === s;
          })[0];
        });

        return {
          schools,
          type: "bluk",
          ...request,
        };
      } else {
        const school = data?.getAllTrusteeSchool.filter(
          (school: any) => school.school_id === request.school_id[0]
        )[0];
        return {
          school,
          ...request,
        };
      }
    }
  );

  return (
    <div>
      <div>
        <Modal
          className="min-h-[80vh] max-w-5xl"
          open={openInvoicePreview}
          setOpen={setOpenInvoicePreview}
        >
          <iframe
            style={{ width: "100%", height: "800px" }}
            src={invoiceUrl}
          ></iframe>
        </Modal>
      </div>
      <RateChargeModal
        open={openSetGatwayModal}
        setOpen={setOpenSetGatwayModal}
      >
        <SetSchoolGatwayRate
          schoolId={schoolID}
          trusteeId={id}
          setOpen={setOpenSetGatwayModal}
        />
      </RateChargeModal>
      <RateChargeModal
        open={openRateChargeModal}
        setOpen={setOpenRateChargeModal}
      >
        {activeTab === 1 && (
          <SchoolRate data={schoolData} setOpen={setOpenRateChargeModal} />
        )}
        {activeTab === 2 && (
          <RequestTab data={singleRequest} setOpen={setOpenRateChargeModal} />
        )}
      </RateChargeModal>
      {data?.getAllTrusteeSchool ? (
        <>
          <Table
            description={
              <div className="flex flex-col gap-y-4 justify-between w-full">
                <div className="flex justify-between items-center">
                  <div className=" w-full flex gap-x-2">
                    <button
                      onClick={() => setActiveTab(1)}
                      className={
                        activeTab === 1
                          ? "bg-[#6687FF] px-4 py-1.5 font-semibold rounded-lg text-[12px]  text-white"
                          : "  py-1.5 px-4 font-semibold bg-transparent border border-[#505E8E80] rounded-lg text-[12px] text-left  text-[#1B163B]"
                      }
                    >
                      Current Charges
                    </button>
                    <button
                      onClick={() => setActiveTab(2)}
                      className={
                        activeTab === 2
                          ? "bg-[#6687FF] px-4 py-1.5 font-semibold rounded-lg text-[12px]  text-white"
                          : "  py-1.5 px-4 font-semibold bg-transparent border border-[#505E8E80] rounded-lg text-[12px] text-left  text-[#1B163B]"
                      }
                    >
                      Request(s) Sent
                    </button>
                    <button
                      onClick={() => setActiveTab(3)}
                      className={
                        activeTab === 3
                          ? "bg-[#6687FF] px-4 py-1.5 font-semibold rounded-lg text-[12px]  text-white"
                          : "  py-1.5 px-4 font-semibold bg-transparent border border-[#505E8E80] rounded-lg text-[12px] text-left  text-[#1B163B]"
                      }
                    >
                      Refund Request(s)
                    </button>
                    <button
                      onClick={() => setActiveTab(4)}
                      className={
                        activeTab === 4
                          ? "bg-[#6687FF] px-4 py-1.5 font-semibold rounded-lg text-[12px]  text-white"
                          : "  py-1.5 px-4 font-semibold bg-transparent border border-[#505E8E80] rounded-lg text-[12px] text-left  text-[#1B163B]"
                      }
                    >
                      Invoice Request(s)
                    </button>
                    <button
                      onClick={() => setActiveTab(5)}
                      className={
                        activeTab === 5
                          ? "bg-[#6687FF] px-4 py-1.5 font-semibold rounded-lg text-[12px]  text-white"
                          : "  py-1.5 px-4 font-semibold bg-transparent border border-[#505E8E80] rounded-lg text-[12px] text-left  text-[#1B163B]"
                      }
                    >
                      Vendors List
                    </button>
                  </div>
                  {/* <button
                    onClick={() => {
                      setOpenBulkMrdModal(true);
                    }}
                    className="bg-[#6687FF] text-white w-full max-w-fit text-[14px] font-medium px-6 py-2 rounded-md"
                  >
                    Updates Rates
                  </button> */}
                </div>
                <div className="flex justify-between w-full">
                  <div className="w-full">
                    {activeTab !== 5 ? (
                      <div className="bg-[#EEF1F6] py-3 items-center flex  px-6 max-w-md w-full rounded-lg">
                        <i className="text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"></i>
                        {activeTab === 1 ? (
                          <input
                            className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
                            onFocus={(e) =>
                              (e.target.style.borderColor = "transparent")
                            }
                            type="text"
                            placeholder=" Search(Institute Name,Order ID...)"
                            value={searchText}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                            }}
                          />
                        ) : activeTab === 3 ? (
                          <input
                            className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
                            onFocus={(e) =>
                              (e.target.style.borderColor = "transparent")
                            }
                            type="text"
                            placeholder=" Search(Refund Request...)"
                            value={searchText}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                            }}
                          />
                        ) : (
                          <input
                            className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
                            onFocus={(e) =>
                              (e.target.style.borderColor = "transparent")
                            }
                            type="text"
                            placeholder=" Search(Request Status...)"
                            value={searchText}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <div>{all filters}</div> */}
                </div>
              </div>
            }
            data={
              activeTab === 1
                ? [
                    [
                      "Name",
                      "Phone Number",
                      "Email ID",
                      "School Limits",
                      "Action",
                    ],
                    ...data?.getAllTrusteeSchool
                      .filter((d: any) => {
                        const arr = [
                          d.school_name,
                          d.email,
                          d.school_id,
                          d.pg_key,
                        ].join(",");
                        return arr
                          .toLowerCase()
                          .includes(searchText.toLowerCase());
                      })
                      .map((d: any, i: number) => [
                        <div
                          onClick={() => {
                            setSchoolData(d);
                            setOpenRateChargeModal(true);
                          }}
                          className="text-center text-violet-500 cursor-pointer"
                        >
                          {d.school_name}
                        </div>,
                        <div className="text-center"></div>,
                        <div className="text-center"></div>,
                        <div className="text-center"></div>,

                        <HiOutlineDotsVertical
                          className="mx-auto cursor-pointer"
                          onClick={() => {
                            setOpenSetGatwayModal(true);
                            setSchoolID(d.school_id);
                          }}
                        />,
                      ]),
                  ]
                : activeTab === 3
                ? [
                    [
                      "Sr.No.",
                      "Order ID",
                      "Order A.",
                      "Transaction A.",
                      "Refund A.",
                      "createdAt",
                      "Gateway",
                      "Status",
                      "Action",
                    ],
                    ...refundRequests?.getRefundRequests
                      .filter((d: any) => {
                        const arr = [
                          d.order_id,
                          d.order_amount,
                          d.transaction_amount,
                          d.refund_amount,
                          d.status,
                          d.gateway,
                        ].join(",");
                        return arr
                          .toLowerCase()
                          .includes(searchText.toLowerCase());
                      })
                      .map((d: any, index: number) => [
                        <div>{index + 1}</div>,
                        <div>{d.order_id}</div>,
                        <div>{d.order_amount}</div>,
                        <div>{d.transaction_amount}</div>,
                        <div>{d.refund_amount}</div>,
                        <div>{new Date(d.createdAt).toLocaleString("hi")}</div>,
                        <div>{d.gateway ? d.gateway : "-"}</div>,
                        <div>{d.status}</div>,
                        <RefundRequestTab data={d} refetch={refetch} />,
                      ]),
                  ]
                : activeTab === 4
                ? [
                    [
                      "Sr.No.",
                      "Invoice No",
                      "Created Date",

                      "Status",
                      "Preview",
                      "Action",
                    ],
                    ...invoices?.getInvoices?.invoices?.map(
                      (d: any, index: number) => [
                        <div>{index + 1}</div>,
                        <div>{d?.invoice_no}</div>,
                        <div>{new Date(d?.createdAt).toDateString()}</div>,
                        <div>{d?.invoice_status}</div>,
                        <div>
                          <FaRegEye
                            onClick={() => {
                              if (d?.invoice_url !== null) {
                                setOpenInvoicePreview(true);
                                setInvoiceUrl(d?.invoice_url);
                              } else {
                                toast.error("No Preview Present");
                              }
                            }}
                            className="text-center cursor-pointer mx-auto"
                          />
                        </div>,
                        <UpdateInvoice data={d} />,
                      ]
                    ),
                  ]
                : activeTab === 5
                ? [
                    [
                      "Sr.No",
                      "Creation Date",
                      "Vendor ID",
                      "Account holder Name",
                      "Status",
                      "Action",
                    ],
                    ...(getVendors?.getVendor?.vendors?.map(
                      (Vendor: any, index: number) => [
                        <div className="cursor-pointer">{index + 1}</div>,
                        <div className=" cursor-pointer">
                          {new Date(Vendor.createdAt).toDateString()}
                        </div>,
                        <div className=" cursor-pointer">{Vendor._id}</div>,
                        <div className=" cursor-pointer">
                          {Vendor?.bank_details?.account_holder}
                        </div>,
                        <div

                        // className={
                        //   "cursor-pointer " +
                        //   (Vendor.status.toLowerCase() === "active"
                        //     ? " text-green-500"
                        //     : Vendor.status.toLowerCase() === "inactive"
                        //       ? "text-gray-500"
                        //       : Vendor.status.toLowerCase() === "suspended"
                        //         ? "text-red-500"
                        //         : "")
                        // }
                        >
                          {Vendor?.status}
                        </div>,
                        <VendorStatusUpdate
                          data={Vendor}
                          fetch={vendorRefetch}
                        />,
                      ]
                    ) || []),
                  ]
                : [
                    [
                      "S.No",
                      "School Name",
                      "Status",
                      "Initiated At",
                      "Last Updated",
                      "Comments",
                    ],
                    ...(filterRequests?.map((d: any, index: number) => [
                      <div>{index + 1}</div>,
                      <div
                        onClick={() => {
                          setSingleRequest(d);
                          setOpenRateChargeModal(true);
                        }}
                        className="cursor-pointer"
                      >
                        {d.school ? d.school.school_name : "Bulk"}
                      </div>,
                      <div
                        onClick={() => {
                          setSingleRequest(d);
                          setOpenRateChargeModal(true);
                        }}
                        className="cursor-pointer"
                      >
                        {d.status}
                      </div>,
                      <div
                        onClick={() => {
                          setSingleRequest(d);
                          setOpenRateChargeModal(true);
                        }}
                        className="cursor-pointer"
                      >
                        {new Date(d.createdAt).toLocaleDateString("hi")}
                      </div>,
                      <div
                        onClick={() => {
                          setSingleRequest(d);
                          setOpenRateChargeModal(true);
                        }}
                        className="cursor-pointer"
                      >
                        {new Date(d.updatedAt).toLocaleDateString("hi")}
                      </div>,
                      <div
                        onClick={() => {
                          setSingleRequest(d);
                          setOpenRateChargeModal(true);
                        }}
                        className="cursor-pointer"
                      >
                        {d.description}
                      </div>,
                    ]) || []),
                  ]
            }
          />

          {/* <div className="flex justify-end mt-4">
            <button
              className="px-4 py-2 bg-[#6F6AF8] rounded-md text-white"
              onClick={async () => {
                setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
                await refetch({
                  page: currentPage > 1 ? currentPage - 1 : 1,
                  pageSize: pageSize,
                });
              }}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="px-4 py-2 rounded-md text-gray-500 text-lg font-bold">
              {" "}
              {currentPage}/{totalPages}{" "}
            </span>
            <button
              className="ml-2 px-4 py-2 bg-[#6F6AF8] rounded-md text-white"
              onClick={async () => {
                setCurrentPage(currentPage + 1);
                await refetch({
                  page: currentPage + 1,
                  pageSize: pageSize,
                });
              }}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div> */}
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default SingleTrusteeSchools;
