import { gql } from "@apollo/client";

export const GET_TRUSTEES_ALL_SCHOOL = gql`
  query GetAllTrusteeSchool($trusteeId: String!) {
    getAllTrusteeSchool(trusteeId: $trusteeId) {
      _id
      platform_charges {
        payment_mode
        platform_type
        range_charge {
          charge
          charge_type
          upto
        }
      }
      school_id
      school_name
    }
  }
`;

export const GET_TRUSTEES_REQUESTS = gql`
  query GetTrusteeRequest($trusteeId: String!) {
    getTrusteeRequest(trusteeId: $trusteeId) {
      _id
      comment
      createdAt
      description
      school_id
      status
      trustee_id
      updatedAt
      platform_charges {
        payment_mode
        platform_type
        range_charge {
          base_charge
          charge
          charge_type
          commission
          upto
        }
      }
    }
  }
`;

export const REVIEW_MRD_REQUEST = gql`
  mutation ReviewMdrRequest(
    $requestId: String!
    $status: String!
    $comment: String!
    $school_id: [String!]!
  ) {
    reviewMdrRequest(
      requestId: $requestId
      status: $status
      comment: $comment
      school_id: $school_id
    )
  }
`;

export const SET_TRUSTEE_BASE_MDR = gql`
  mutation SetBaseMdr(
    $platform_charges: [platformChargeInput!]!
    $comment: String!
    $trustee_id: ID!
  ) {
    setBaseMdr(
      base_mdr: {
        comment: $comment
        platform_charges: $platform_charges
        trustee_id: $trustee_id
      }
    )
  }
`;

export const GET_REFUND_REQUESTS_BY_TRUSTEE_ID = gql`
  query GetRefundRequests($trustee_id: String!, $page: Float, $limit: Float) {
    getRefundRequests(trustee_id: $trustee_id, page: $page, limit: $limit) {
      createdAt
      gateway
      order_amount
      order_id
      refund_amount
      school_id
      status
      transaction_amount
      trustee_id
      updatedAt
      _id
    }
  }
`;

export const UPDATE_REFUND_REQUESTS = gql`
  mutation UpdateRefundRequest($refund: String!, $status: String!) {
    updateRefundRequest(refund: $refund, status: $status)
  }
`;

export const GET_TRUSTEE_GATEWAY_RATIO = gql`
  query GetTrusteeGatewayRatio($trustee_id: String!) {
    getTrusteeGatewayRatio(trustee_id: $trustee_id) {
      cashfree
      easebuzz
      mode
    }
  }
`;

export const GET_SCHOOL_GATEWAY_RATIO = gql`
  query GetSchoolGatewayRatio($school_id: String!) {
    getSchoolGatewayRatio(school_id: $school_id) {
      cashfree
      easebuzz
      mode
    }
  }
`;

export const GET_INVOICES = gql`
  query GetInvoices($limit: Int!, $page: Int!, $trustee_id: String!) {
    getInvoices(limit: $limit, page: $page, trustee_id: $trustee_id) {
      currentPage
      totalInvoices
      totalPages
      invoices {
        _id
        invoice_no
        invoice_url
        trustee_id
        createdAt
        invoice_status
        reason
      }
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice(
    $invoice_id: String!
    $status: String!
    $reason: String!
  ) {
    updateInvoice(invoice_id: $invoice_id, status: $status, reason: $reason)
  }
`;

export const INITIATE_REQUEST = gql`
  mutation InitiateRefund(
    $amount: Float!
    $collect_id: String!
    $refund_id: String!
  ) {
    initiateRefund(
      amount: $amount
      collect_id: $collect_id
      refund_id: $refund_id
    )
  }
`;

export const GET_VENDORS = gql`
  query GetVendor($trustee_id: String!, $limit: Int!, $page: Int!) {
    getVendor(trustee_id: $trustee_id, limit: $limit, page: $page) {
      currentPage
      totalPages
      vendors {
        _id
        createdAt
        email
        name
        phone
        school_id
        status
        trustee_id
        updatedAt
        vendor_id
        bank_details {
          account_holder
          account_number
          ifsc
        }
        kyc_info {
          account_type
          business_type
          chequeUrl
          cin
          gst
          pan
          passport_number
          uidai
        }
      }
    }
  }
`;

export const UPDATE_VENDOR_STATUS = gql`
  mutation UpdateVendorStatus(
    $school_id: String!
    $trustee_id: String!
    $vendor_id: String!
  ) {
    updateVendorStatus(
      school_id: $school_id
      trustee_id: $trustee_id
      vendor_id: $vendor_id
    )
  }
`;
